
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { rediredAsPerLoggedInUser } from "@/filters";

interface IClient {
  id: number;
  name: string;
  company: string;
  email: string;
}

export default defineComponent({
  name: "reviewer-invite",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const submitButton = ref<HTMLButtonElement | null>(null);
    let client = ref<IClient>({ id: 0, name: "", company: "", email: "" });
    let showCreate = ref<boolean>(false);
    let showLoading = ref<boolean>(true);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      password: Yup.string().required().label("Password"),
    });

    onMounted(() => {
      //check if current user is authenticated
      if (store.getters.isUserAuthenticated) {
        rediredAsPerLoggedInUser(store, router);
      }
      const token = route.query.token;
      if (token) {
        const tokenRequest: any = { token: token };
        ApiService.post("verify-review-invite/", tokenRequest)
          .then(({ data }) => {
            showLoading.value = false;
            client.value = data.reviewer;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    });

    //Form submit function
    const createClientAccount = async (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send client signup request
      const client_signup_params: any = {
        reviewer_token: route.query.token,
        password: values.password,
        username: client.value.email,
        email: client.value.email,
      };
      await store.dispatch(Actions.CONFIRM_REVIEWER_SIGNUP, client_signup_params);

      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];

      if (!error) {
        Swal.fire({
          text: "You have successfully created your account!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          // Go to page after successfully login
          router.push({ name: "admin-home" });
        });
      } else {
        Swal.fire({
          text: error[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
    };

    return {
      createClientAccount,
      forgotPassword,
      submitButton,
      client,
      showLoading,
      showCreate,
    };
  },
});
